import purchase from "../../../../blog/2019-12-15-five-more-years/purchaseteam.jpg";
import mxteam from "../../../../blog/2019-12-15-five-more-years/mxteam.png";
import greguniverse from "../../../../blog/2019-12-15-five-more-years/greguniverse.jpg";
import ikea from "../../../../blog/2019-12-15-five-more-years/ikea.jpg";
import eclipse from "../../../../blog/2019-12-15-five-more-years/eclipse.jpg";
import pianocat from "../../../../blog/2019-12-15-five-more-years/pianocat.jpg";
import moya from "../../../../blog/2019-12-15-five-more-years/moya.png";
import comingout from "../../../../blog/2019-12-15-five-more-years/comingout.jpg";
import cultureamp from "../../../../blog/2019-12-15-five-more-years/cultureamp.png";
import cityguides from "../../../../blog/2019-12-15-five-more-years/cityguides.jpg";
import elephantfriend from "../../../../blog/2019-12-15-five-more-years/elephantfriend.png";
import peerlabcommunity from "../../../../blog/2019-12-15-five-more-years/peerlabscommunity.jpg";
import inputoutput from "../../../../blog/2019-12-15-five-more-years/inputoutput.jpg";
import buynow from "../../../../blog/2019-12-15-five-more-years/buynow.jpg";
import greencardend from "../../../../blog/2019-12-15-five-more-years/greencardend.jpg";
import lifeatartsy from "../../../../blog/2019-12-15-five-more-years/lifeatartsy.jpg";
import bidflow from "../../../../blog/2019-12-15-five-more-years/bidflow.png";
import jamsesh from "../../../../blog/2019-12-15-five-more-years/jamsesh.jpg";
import dotSwift from "../../../../blog/2019-12-15-five-more-years/dotSwift.jpg";
import leaving from "../../../../blog/2019-12-15-five-more-years/leaving.jpg";
import cats from "../../../../blog/2019-12-15-five-more-years/cats.jpg";
import armory from "../../../../blog/2019-12-15-five-more-years/armory.jpg";
import hamburg from "../../../../blog/2019-12-15-five-more-years/hamburg.jpg";
import uikonf from "../../../../blog/2019-12-15-five-more-years/uikonf.jpg";
import drawing from "../../../../blog/2019-12-15-five-more-years/drawing.jpg";
import officeatnight from "../../../../blog/2019-12-15-five-more-years/officeatnight.jpg";
import pragma from "../../../../blog/2019-12-15-five-more-years/pragma.jpg";
import film from "../../../../blog/2019-12-15-five-more-years/film.jpg";
import lai from "../../../../blog/2019-12-15-five-more-years/lai.png";
import thumbsup from "../../../../blog/2019-12-15-five-more-years/thumbsup.jpg";
import sinfo from "../../../../blog/2019-12-15-five-more-years/sinfo.jpg";
import lailaunch from "../../../../blog/2019-12-15-five-more-years/lailaunch.jpg";
import buttons from "../../../../blog/2019-12-15-five-more-years/buttons.jpg";
import stevenuniverse from "../../../../blog/2019-12-15-five-more-years/stevenuniverse.jpg";
import election from "../../../../blog/2019-12-15-five-more-years/election.jpg";
import posts from "../../../../blog/2019-12-15-five-more-years/posts.png";
import * as React from 'react';
export default {
  purchase,
  mxteam,
  greguniverse,
  ikea,
  eclipse,
  pianocat,
  moya,
  comingout,
  cultureamp,
  cityguides,
  elephantfriend,
  peerlabcommunity,
  inputoutput,
  buynow,
  greencardend,
  lifeatartsy,
  bidflow,
  jamsesh,
  dotSwift,
  leaving,
  cats,
  armory,
  hamburg,
  uikonf,
  drawing,
  officeatnight,
  pragma,
  film,
  lai,
  thumbsup,
  sinfo,
  lailaunch,
  buttons,
  stevenuniverse,
  election,
  posts,
  React
};