import keyboard from "../../../../blog/2025-02-18-personal-timeline/keyboard.jpeg";
import childhood from "../../../../blog/2025-02-18-personal-timeline/childhood.jpeg";
import toronto from "../../../../blog/2025-02-18-personal-timeline/500px.jpeg";
import uhaul from "../../../../blog/2025-02-18-personal-timeline/uhaul.jpeg";
import coffeetimer from "../../../../blog/2025-02-18-personal-timeline/coffeetimer.jpeg";
import wwdc from "../../../../blog/2025-02-18-personal-timeline/wwdc.jpeg";
import camera from "../../../../blog/2025-02-18-personal-timeline/camera.jpeg";
import peerlab from "../../../../blog/2025-02-18-personal-timeline/peerlab.jpeg";
import amsterdam from "../../../../blog/2025-02-18-personal-timeline/amsterdam.jpeg";
import artsy from "../../../../blog/2025-02-18-personal-timeline/artsy.jpeg";
import wedding from "../../../../blog/2025-02-18-personal-timeline/wedding.jpeg";
import computers from "../../../../blog/2025-02-18-personal-timeline/computer.jpeg";
import guitar from "../../../../blog/2025-02-18-personal-timeline/guitar.jpeg";
import nyc from "../../../../blog/2025-02-18-personal-timeline/nyc.jpeg";
import programming from "../../../../blog/2025-02-18-personal-timeline/programming.jpg";
import float from "../../../../blog/2025-02-18-personal-timeline/float.jpeg";
import shopify from "../../../../blog/2025-02-18-personal-timeline/shopify.jpeg";
import speaking from "../../../../blog/2025-02-18-personal-timeline/speaking.jpeg";
import * as React from 'react';
export default {
  keyboard,
  childhood,
  toronto,
  uhaul,
  coffeetimer,
  wwdc,
  camera,
  peerlab,
  amsterdam,
  artsy,
  wedding,
  computers,
  guitar,
  nyc,
  programming,
  float,
  shopify,
  speaking,
  React
};