import xcode from "../../../../blog/2014-12-21-5-years-of-ios/xcode.png";
import coffeetimericon from "../../../../blog/2014-12-21-5-years-of-ios/coffeetimer.png";
import wwdc2010 from "../../../../blog/2014-12-21-5-years-of-ios/wwdc2010.jpg";
import coffeetimer2 from "../../../../blog/2014-12-21-5-years-of-ios/coffeetimer2.png";
import unb from "../../../../blog/2014-12-21-5-years-of-ios/unb.png";
import simulator from "../../../../blog/2014-12-21-5-years-of-ios/simulator.png";
import wedding from "../../../../blog/2014-12-21-5-years-of-ios/wedding.jpg";
import graduated from "../../../../blog/2014-12-21-5-years-of-ios/graduated.jpg";
import started500px from "../../../../blog/2014-12-21-5-years-of-ios/started500px.jpg";
import _500px from "../../../../blog/2014-12-21-5-years-of-ios/500px.jpg";
import podcast from "../../../../blog/2014-12-21-5-years-of-ios/podcast.jpg";
import storygram from "../../../../blog/2014-12-21-5-years-of-ios/storygram.png";
import startedphotography from "../../../../blog/2014-12-21-5-years-of-ios/startedphotography.jpg";
import apple from "../../../../blog/2014-12-21-5-years-of-ios/apple.jpg";
import photohour from "../../../../blog/2014-12-21-5-years-of-ios/photohour.png";
import wwdc2012 from "../../../../blog/2014-12-21-5-years-of-ios/wwdc2012.jpg";
import fitc from "../../../../blog/2014-12-21-5-years-of-ios/fitc.jpg";
import _500pxiphone from "../../../../blog/2014-12-21-5-years-of-ios/500pxiphone.jpg";
import springboard from "../../../../blog/2014-12-21-5-years-of-ios/springboard.jpg";
import _35mm from "../../../../blog/2014-12-21-5-years-of-ios/35mm.png";
import notificationcenter from "../../../../blog/2014-12-21-5-years-of-ios/notificationcenter.jpg";
import amsterdam from "../../../../blog/2014-12-21-5-years-of-ios/amsterdam.jpg";
import artsy from "../../../../blog/2014-12-21-5-years-of-ios/artsy.jpg";
import artbasel from "../../../../blog/2014-12-21-5-years-of-ios/artbasel.jpg";
import conferences from "../../../../blog/2014-12-21-5-years-of-ios/conferences.jpg";
import eidolon from "../../../../blog/2014-12-21-5-years-of-ios/eidolon.jpg";
import film from "../../../../blog/2014-12-21-5-years-of-ios/film.jpg";
import * as React from 'react';
export default {
  xcode,
  coffeetimericon,
  wwdc2010,
  coffeetimer2,
  unb,
  simulator,
  wedding,
  graduated,
  started500px,
  _500px,
  podcast,
  storygram,
  startedphotography,
  apple,
  photohour,
  wwdc2012,
  fitc,
  _500pxiphone,
  springboard,
  _35mm,
  notificationcenter,
  amsterdam,
  artsy,
  artbasel,
  conferences,
  eidolon,
  film,
  React
};